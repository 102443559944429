<template lang="pug">
.footer
  .footer_content.w-full
    .footer_header.w-full
      .footer_header_block(v-show="isDefault")
        NuxtLink.footer_header_block_link(to="/sponsor_licence/") Sponsor Licence
        |
        NuxtLink.footer_header_block_link(to="/skilled-worker-visa-application/") Skilled Worker Visa
        |
        NuxtLink.footer_header_block_link(to="/sponsorship-compliance-platform/") Immigration Compliance and Audit
        |
        NuxtLink.footer_header_block_link(v-show="false" to="/sponsor-licence-guidance/") Immigration Compliance and Audit
      |
      .footer_header_block(v-show="isDefault")
        NuxtLink.footer_header_block_link(to="/pricing/") Pricing
        |
        NuxtLink.footer_header_block_link(to="/about-us/") About us
        |
        NuxtLink.footer_header_block_link(to="/blog/") Blog
      |
      .footer_header_block.block-right
        a.support(
          href="mailto:support@nationbetter.uk"
        ) support@nationbetter.uk
        .address 128 City Road, London, EC1V 2NX,  United Kingdom
        .social
          NuxtLink(to="https://www.facebook.com/nation.better" target="_blank")
            NuxtImg(src="/assets/images/social/facebook.webp" alt="facebook" loading="lazy" height="40")
          NuxtLink(to="https://www.instagram.com/nationbetter.uk/" target="_blank")
            NuxtImg(src="/assets/images/social/instagram.webp" alt="instagram" loading="lazy" height="40")
          NuxtLink(to="https://www.linkedin.com/company/nation-better/" target="_blank")
            NuxtImg(src="/assets/images/social/linkedin.webp" alt="linkedin" loading="lazy" height="40")
    |
    .company-info.flex.w-full
      NuxtImg.logo(src="/assets/images/logo/logo_dark_bg.webp" alt="logo")
      |
      p.descr.light BetterNations LTD, who is trading under the name ‘Nation.better’ (‘Nation.better’), is a company registered in England and Wales with company number 12066785. Nation.better is a software development company providing software-as-a-service (SaaS). BetterNations LTD is registered as a Data Controller under the ICO: ZA795311
    |
    .line
    |
    .credits.flex.justify-between.w-full
      .credits-item
        .credit-img
          NuxtImg(src="/assets/images/credit/iaa.webp" alt="IAA" loading="lazy" height="48")
        p.light.credit-1 Nation.better is registered with the Immigration Advice Authority (IAA), Ref. No: F202100313
      
      .credits-item.credit-2-content
        .flex.gap-2.credit-2-images
          .credit-img
            NuxtImg(src="/assets/images/credit/gdpr.webp" alt="GDPR" loading="lazy" height="48")
          .credit-img
            NuxtImg(src="/assets/images/credit/ico.webp" alt="ico" loading="lazy" height="48")
        p.light.credit-2 The privacy and security of your information are our top priorities
  |
  .footer_copyright
    p Copyright © BetterNations Ltd 2025. All Rights Reserved
    |
    .links(
      v-if="!footer?.additionalLinks?.length"
    )
      NuxtLink(to="/terms-and-conditions") Terms and conditions
      NuxtLink(to="/privacy-policy") Privacy policy
      NuxtLink(to="/cookie-policy") Cookie policy
      //- a(
      //-   href="https://drive.google.com/file/d/1z7R48-Bd_KQvfLfyFxrJaD-jHE47Pn1P/view"
      //- ) Terms and conditions
      //- a(
      //-   href="https://drive.google.com/file/d/1SPQp1tAIjKR7BnK6BhscZF9et-ddSVDP/view"
      //- ) Privacy policy
      //- a(
      //-   href="https://drive.google.com/file/d/1k1BArPfk_jfb5B2xArFFp9zqBrqxhZbO/view"
      //- ) Cookie policy
    |
    .links(
      v-else
    )
      NuxtLink(
        v-for="(link, i) in footer.additionalLinks"
        :key="i"
        :to="link.to || ''"
      ) {{ link.label || '' }}
</template>


<script setup lang="ts">
import { computed } from 'vue';
import type { TFooter } from "@/models/page";

interface IProps {
  footer?: TFooter | null;
}

const props = defineProps<IProps>();

const footer = computed(() => props.footer || {
  type: "default",
  additionalLinks: [],
});

const isDefault = computed(() => footer.value.type === "default");
</script>

<style lang="sass" scoped>
.footer
  background: #074033
  color: #F8FAF2
  display: flex
  flex-direction: column
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 20px
  row-gap: 0

  .footer_header
    display: flex
    gap: 48px

    &_block
      display: flex
      flex-direction: column

      &.block-right
        text-align: right
        flex: 1

      &_link
        text-decoration: none
        color: var(--darkilimegreen-25, #F8FAF2)
        font-size: 18px
        font-style: normal
        font-weight: 600
        line-height: 220%
        transition: 0.3s

        &:hover
          opacity: 0.8

  &_content
    padding: 75px 142px 65px 142px

    .support, .address
      font-size: 18px
      font-style: normal
      font-weight: 500
      line-height: 180%

    .social
      display: flex
      align-items: center
      justify-content: flex-end
      gap: 8px
      margin-top: 20px
      margin-bottom: 96px

      img
        cursor: pointer
        transition: 0.2s
        height: 40px
        object-fit: contain

        &:hover
          opacity: 0.8

    .company-info
      align-items: center

      .logo
        aspect-ratio: 127 / 54
        object-fit: cover
        width: 127px
        height: 54px

    .light
      color: #ABE4D4
      font-size: 14px
      font-style: normal
      font-weight: 400
      line-height: 20px

    .credits
      img
        height: 48px
        object-fit: contain

    .credits-item
      display: flex
      gap: 32px
      align-items: center

    .credit-1
      width: 496px

    .credit-2
      width: 216px

    .descr
      width: 752px
      margin-left: 52px

    .line
      background: #476961
      width: 100%
      height: 1px
      margin-top: 40px
      margin-bottom: 35px
    
  &_copyright
    background: #00261E
    color: #C9F0E5
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    padding: 25px 142px

    .links
      display: flex
      gap: 32px

    p 
      color: #C9F0E5
      font-size: 14px
      font-style: normal
      font-weight: 400
      line-height: 20px

a.support
  text-decoration-line: underline
  text-decoration-style: solid
  text-decoration-skip-ink: none
  text-decoration-thickness: auto
  text-underline-offset: auto
  text-underline-position: from-font
  cursor: pointer
  transition: 0.2s

  &:hover
    opacity: 0.8

@media (max-width: 1024px)
  .footer_header
    flex-direction: column

  .footer_content
    padding: 50px 5vw 50px 5vw
    
    .descr
      margin-left: 0
      width: 100%

    .social
      justify-content: center
      margin-bottom: 40px

    .credit-1, .credit-2
      width: 100%

    .flex.justify-between
      flex-direction: column
      gap: 20px

    .flex.gap-6
      justify-content: center

    .company-info
      flex-direction: column
      gap: 8px
      align-items: flex-start

      img
        width: 127px

    .social 
      justify-content: flex-start

      img
        width: 40px
        height: 40px

  .footer_copyright
    padding: 20px 5vw
    flex-direction: column
    align-items: center
    text-align: center

    .links
      margin-top: 15px
      gap: 16px

@media (max-width: 768px)
  .footer
    .footer_content
      .support, .address
        font-size: 16px
        line-height: 24px
        font-weight: 400

    .footer_copyright
      p
        font-size: 12px
        line-height: 18px

      .links
        flex-direction: column
        gap: 8px

    .footer_header
      text-align: left

      &_block.block-right
        text-align: left

    .credit-2-content
      align-items: flex-start

    .credits
      height: auto
      min-height: 236px

    .company-info
      height: auto
      min-height: 282px
      align-items: flex-start

    .credit-img
      width: 90px
      min-width: 90px

    .descr
      margin-top: 48px
      margin-bottom: 32px

    .credits-item
      display: flex
      flex-direction: column-reverse
      align-items: flex-start
      gap: 20px

    .line
      display: none
</style>
  